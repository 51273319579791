/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'

import appsAndPagesPaid from './apps-and-pages-paid'
import appsAndPages from './apps-and-pages'
import others from './others'
import uiElements from './ui-elements'

var state= JSON.parse(localStorage.getItem('userData'));
console.log("state",state);
var pagesToDisplay=[];
if(state.payment && state.payment.length > 0){
    var now = new Date();
    var paymentDate = new Date(state.payment[state.payment.length-1].paymentDate);
    var validityPeriods = {
        basicPlan: 30,  // 30 days
        standardPlan: 180, // 6 months (approx)
        enterprisePlan: 365, // 1 year
        premiumPlan: 365, // 1 year
        trial: 15 // 15 days
    };
    var diffDays = (now - paymentDate) / (1000 * 60 * 60 * 24);
    state.offer = diffDays < validityPeriods[state.payment[state.payment.length-1].offreType];
}
console.log("state.offer",state.offer);

if(state.payment == [] || state.payment == undefined || state.payment == null || state.offer==false){
    pagesToDisplay = [...dashboard, ...appsAndPages, ...uiElements, ...others]
}else{
// Array of sections
    pagesToDisplay = [...dashboard, ...appsAndPages, ...appsAndPagesPaid, ...uiElements, ...others]
}
export default pagesToDisplay

